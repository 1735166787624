import IceChain from '../ice-chain'

export const cronos: IceChain = {
  id: 25,
  name: 'Cronos',
  features: ['swap','farms', 'staking'],
  network: 'cronos',
  rpcUrls: {
    public: { http: ['https://cronos-evm-rpc.publicnode.com',] },
    default: { http: ['https://cronos-evm-rpc.publicnode.com',] },
  },
  blockExplorers: {
    default: { name: 'Cronos Explorer', url: 'https://cronoscan.com' },
  },
  nativeCurrency: {
    name: 'CRO',
    symbol: 'CRO',
    decimals: 18,
  },
  contracts: {
    multicall3: {
      address: '0xd5fFC42B1E8f2C32EA522a36c0F131E88a69983c',
      blockCreated: 14855526,
    },
  },
  blockInterval: 5.5,
  wrappedNative: {
    address: '0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23',
    decimals: 18,
    symbol: 'WCRO',
    name: 'Wrapped CRO',
  },
  iceAddress: '0x664E4b17EA045fE92868821F3eE0a76a5dB38166',
  stableToken: {
    address: '0x66e428c3f67a68878562e79A0234c1F83c208770',
    decimals: 6,
    symbol: 'USDT',
    name: 'Tether USD',
  },
  swap: {
    factoryAddress: '0xCd2E5cC83681d62BEb066Ad0a2ec94Bf301570C9',
    initCodeHash: '0x7f04bf7dc3ca17c22431ffc2f5698b4703fb9b3c8e862a285f06955ef9a84967',
    routerAddress: '0x8EbC409998ef75661A4C464ff9bbb490586F954a',
  },
  farmV2Address: '0x8a44aC7D38B9925D2437803520ED38ae5C3120e5',
  /*
  v3:{
    factoryAdress:'0x94fFa46F0831311152F0dbe5D4C74d54268533d5'
    initCodeHash: '0xf16b1c067611794c79bef6ff7630d4a4c43b3be76c08085ce934747817a6b322',
  },
  */
  smartRouterAddress: '0xeb02A792A9a85c00498A72b13B9aA5c486bC6cA1',
}